

.tree, .tree ul {
    margin:0;
    padding:0;
    list-style:none
}
.tree ul {
    margin-left:1em;
    position:relative
}
.tree ul ul {
    margin-left:.5em
}
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top: 0;
    bottom:0;
    left: -10px;
    border-left:1px solid #ededf5;
}
.tree li {
    margin:0;
    padding: 0.5em 1em 0.2em 1em;
    line-height:2em;
    color: #1a1630;
    position:relative;
    border: 1px solid #ebecf1;
    margin: 10px 0;
    border-radius: 5px;
    line-height: 30px;
}
.tree li i{
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
    /* padding-top: 10px; */
    line-height: 1;
}
.tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top: 2px solid #e5e5ea;
    margin-top:-1px;
    position:absolute;
    top: 22px;
    left: -10px;
}
.tree ul li:last-child:before {
    background:transparent;
    height:auto;
    top: 22px;
    bottom:0;
}
.indicator {
    margin-right:5px;
	color: #4b10a4;
}
.tree li a {
    text-decoration: none;
    color: #1a1630;
    font-size: 15px;
}
.tree li button, .tree li button:active, .tree li button:focus {
    text-decoration: none;
    color:#29273c;
    border:none;
    background:transparent;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    outline: 0;
}
.tree li.branch {
	background:#ffffff;
}
.tree li.branch li{
	background: #ffffff;
}